<template>
  <div>
    <div>
      <ElXlsx @upData="upData" />
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="project" label="项目">
      </el-table-column>
      <el-table-column prop="budget" label="预算">
      </el-table-column>
      <el-table-column prop="cumulative" label="累计实际">
      </el-table-column>
      <el-table-column prop="earliestExpiration" label="累计完成比例">
      </el-table-column>
      <el-table-column prop="yearFrom" label="去年同期">
      </el-table-column>
      <el-table-column prop="date" label="日期">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import ElXlsx from '../../components/ElXlsx.vue'
  export default {
    components: {
      ElXlsx
    },
    inject: ['reload'],
    data() {
      return {
        tableData: [],
        items: ['project', 'budget', 'cumulative', 'earliestExpiration', 'yearFrom', 'date']
      }
    },
    mounted() {
      this.$axios.post('/totle-index/findList').then(res => {
        this.tableData = res.data.data
      })
    },
    methods: {
      upData(e) {
        let dataArr = this.$replaceString(e, this.items)
        this.$axios.post('/totle-index/save', dataArr).then(res => {
          this.$message({
            message: '导入成功。',
            type: 'success'
          })
          this.reload()
        })
      },
    }
  }
</script>

<style scoped>
  .el-table {
    margin-top: 20px;
  }
</style>
